import { NO_PHOTO_PATH, PRIMARY_DOMEN } from "@/shared/helpers/data/globalHelper";

export const li = (name: string): string => {
  if (name) {
    if (name.startsWith("/upload/")) {
      return PRIMARY_DOMEN + name;
    }
    return `/images/pictures/${name}`;
  } else return NO_PHOTO_PATH;
};
